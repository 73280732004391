.wrapper {
  padding-top: 36px;
  input {
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
  }
  &__top {
    display: flex;
    &__date {
      display: inline-block;
      font-weight: 500;
      color: var(--secondaryText);
      transform: translateY(-3px);
      white-space: nowrap;
    }
    & > div:nth-child(2) {
      margin-left: 7.5%;
      min-width: 35%;
      max-width: 45%;
    }
    & > div:last-child {
      margin: 0 auto;
      border-left: 2px solid var(--borderColor);
      padding-left: 30px;
      position: relative;
    }
    .dialogBtn {
      font-size: 18px;
      margin-left: 0;
      display: flex;
      align-items: center;
      font-weight: 400;
      &:last-child {
        margin-top: 16px;
      }
      span {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

}

@media(max-width: 1600px) {
  .wrapper {
    &__top {
      & > div:nth-child(2) {
        margin-left: 4%;
        // max-width: 45%;
      }
    }
  }
}

@media(max-width: 800px) {
  .wrapper {
    &__top {
      flex-direction: column;
      &__date {
      
      }
      & > div:nth-child(2) {
        margin-left: 0%;
        min-width: 35%;
        max-width: 100%;
        margin: 24px 0;
      }
      & > div:last-child {
        margin-left: 0;
        border-left: none;
        padding-left: 0;
        position: relative;
      }
    }
  }
}