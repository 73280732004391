.outerWrapper {
  .divider {
    height: 2px;
    background-color: var(--secondary);
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.wrapper {
  width: 100%;
  // border: 2px solid var(--borderColor);
  border-radius: 6px;
  min-height: 100px;
  margin-top: 42px;
  padding: 0;
  select {
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background-color: var(--secondary);
    color: var(--main);
    min-height: 40px;
  }
  input, textarea {
    width: 100%;
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 200;
    }
  }
  textarea {
    min-height: 80px;
  }
}

.autocomplete {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover);
    }
  }
}

.inputStyle {
  width: 100%;
  margin-top: 2px;
  border: 1px solid var(--borderColor);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-family: inherit;
  background-color: var(--secondary);
  color: var(--main);
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  & > button {
    margin-left: 10px;
  }
  & > button:first-child {
    margin-left: 0;
  }
  & > button:last-child {
    margin-right: 0;
  }
}