.navbarMenu {
  position: sticky !important;
  top: 0;
  display: flex;
  flex-direction: column;
  // padding-left: 10px !important;
  background: var(--sidebarBg);
  height: 100vh;
  padding-top: 120px !important;
  transition: min-width .5s cubic-bezier(.52,.01,0,1), width .5s cubic-bezier(.52,.01,0,1);
  min-width: 80px;
  width: 80px;
  box-shadow: 2px 0px 3px 0px rgba(0, 0, 0, 0.1);
  z-index: 150;
  .listItem {
    transition: height .3s linear, padding .3s linear;
    height: 57px;
    padding: 36px 0 36px 22px !important;
    overflow: hidden;
  }
  .listItem__text {
    opacity: 0;
    transition-delay: .5s;
    transition: opacity .1s linear;
    transition-delay: 0s;
  }
  .listItem__icon {
    transition: all .2s linear;
    min-width: 35px;
    margin-right: 0;
  }
 
  &.expanded {
    min-width: 275px;
    width: 275px;
    .listItem__icon {
      min-width: 45px;
      margin-right: 25px;
    }
    .listItemStaff .listItem__icon {
      min-width: 40px;
    }
    .listItem {
      height: 77px;
      padding: 18px 10px 18px 25px !important;
    }
    .listItem__text {
      opacity: 1;
      transition-delay: .3s;
      transition-duration: .3s;
    }
  }

  .link {
    text-decoration: none;
    color: #9CA3AF !important;
    position: relative;
    transition: all 300ms linear;
    font-weight: 500;
    &:hover {
      background-color: var(--lightHover);
    }
    &.active {
      font-weight: 600 !important;
      color: var(--main) !important;
      transition: color 0s;
      .listItem__icon {
        color: var(--accent) !important;
        transition: color 0s;
        &:hover {
          color: var(--accentHover) !important;
        }
      }
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: var(--accent);
        &:hover {
          background-color: var(--accentHover);
        }
      }
    }
  }
}



.listItemIcon {
  min-width: 40px !important;
}
.burger {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 36px;
  cursor: pointer;
  &.mobileBurger {
    right: auto;
    left: 12px;
  }
}

@media(max-width: 800px) {
  .navbarMenu {
    position: fixed !important;
    left: 0;
    transform: translate(-100%);
    transition: min-width .5s cubic-bezier(.52,.01,0,1), width .5s cubic-bezier(.52,.01,0,1), transform .5s cubic-bezier(.52,.01,0,1);
  }
  .navbarMenu.expanded {
    transform: translate(0);
  }
  .burger.mobileBurger {

  }
}