.chart, .remove {
  cursor: pointer;
  color: var(--errorRed);
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  &:hover {
    color: var(--errorRedHover);
  }
}

.remove {
  padding-left: 15px;
}

.chart {
  color: var(--accent);
  &:hover {
    color: var(--accentHover);
  }
}

.editable {
  // cursor: pointer;
  color: var(--accent);
  display: flex;
  align-items: center;
  font-size: 16px;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    display: inline-block;
    margin-left: 3px;
  }
  &:hover {
    color: var(--accentHover);
  }
}

.indicatorInput {
  width: 50px;
  margin-top: 2px;
  border: 1px solid var(--borderColor);
  padding: 6px;
  border-radius: 6px;
  font-size: 16px;
  font-family: inherit;
  background-color: var(--secondary);
  color: var(--main);
}

.addNewBtn {
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(65%);
}

@media(max-width: 800px) {
  .addNewBtn {
    top: 20px;
  }
}