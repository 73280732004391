.table {
  min-width: 1150px;
  border: 2px solid var(--borderColor);
  border-radius: 6px !important;
  border-collapse: separate !important;
  thead {
    border-radius: 6px;
    background: var(--tableHeaderBg);
    tr {
      th:first-child {
        display: none;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        display: none;
      }
     
    }
  }
}

.tableHead {

  .tableHeaderCell {
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    color: var(--main) !important;
    position: relative;
    .tableSorterWrapper {
      position: absolute;
      right: 2px;
      top: 1px;
      display: inline-block;
      transform: translate(100%);
      svg {
        fill: var(--accent) !important;
        &:hover {
          fill: var(--accentHover) !important;
        }
      }
    }
  }

}

.dangerRow {
  animation: errorAnimation infinite 8s;

}

.warningRow {
  animation: warningAnimation infinite 8s;
}

.tableRow {
  background: transparent;
  position: relative;
  
  &:hover {
    background-color: var(--secondary) !important;
  }
  &:nth-child(odd) {
    background-color: var(--tableOddRow);
    &.dangerRow {
      animation: errorAnimationOdd infinite 8s;
    }
    &.warningRow {
      animation: warningAnimationOdd infinite 8s;
    }
  }
}

.tableCell {
  color: var(--tableCellTextColor) !important;
  font-size: 15px;
  font-weight: 500;
  border-bottom: none !important;
  padding: 8px 16px !important;
}

.warningIcon {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  &.error {
    color: var(--errorRed);
  }
  &.warning {
    color: var(--warningYellow);
  }
}

.indicatorsTable {
  min-width: 650px;
  .tableCell {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  
}

.diagnosesTable {
  min-width: 650px;
}

.treatmantIndicatorsTable {
  min-width: 850px;
}

.descriptiveTable, .usersTable {
  min-width: auto;
  width: 100%;
}

.arrow {
  font-size: 22px;
}



// .clicked {
//   animation: none !important;
// }

@keyframes errorAnimation {
  0% {
    background-color: transparent;
  }
  12% {
    background-color: transparent;
  }
  25% {
    background-color: #ffa8a8;
  }
  37% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes errorAnimationOdd {
  0% {
    background-color: var(--tableOddRow);
  }
  12% {
    background-color: var(--tableOddRow);
  }
  25% {
    background-color: #ffa8a8;
  }
  37% {
    background-color: var(--tableOddRow);
  }
  100% {
    background-color: var(--tableOddRow);
  }
}

@keyframes warningAnimation {
  0% {
    background-color: transparent;
  }
  12% {
    background-color: transparent;
  }
  25% {
    background-color: #f6ff00;
  }
  37% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes warningAnimationOdd {
  0% {
        background-color: var(--tableOddRow);
  }
  12% {
        background-color: var(--tableOddRow);
  }
  25% {
    background-color: #f6ff00;
  }
  37% {
        background-color: var(--tableOddRow);
  }
  100% {
        background-color: var(--tableOddRow);
  }
}