.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  display: flex;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  & > div:first-child { flex: 7; }
  & > div:nth-child(2) { 
    overflow: hidden;
    flex: 0;
    opacity: 0;
    max-height: 0;
    transition: flex .5s cubic-bezier(.52,.01,0,1), opacity .25s ease-in-out 0s;
   }
  &.formExpanded  {
    & > div:nth-child(2) { 
      max-height: none;
      margin-left: 20px;
      flex: 2;
      opacity: 1;
      transition: flex .5s cubic-bezier(.52,.01,0,1), opacity .25s ease-in-out .5s;
    }
  }
}

@media(max-width: 1600px) {
  .grid {
    & > div:first-child { flex: 6; }
    &__form {
      &.opened {
        flex: 6;
        overflow: hidden;
      }
    }
    &.formExpanded  {
      & > div:nth-child(2) { 
        flex: 3;
      }
    }
  }
}

.tableGrid {
  margin-top: 16px;
}

@media(max-width: 800px) {
  .tableGrid {
    margin-top: 60px;
  }
}