.indicatorWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
    & > div:first-child {
      grid-column: span 12 / span 12;
    }
    & > div:nth-child(2) {
      grid-column: span 5 / span 5;
    }
}

.createNewBtn {
  // position: absolute;
  // top: -8px;
  margin-left: auto;
  display: flex;
  // transform: translateY(-100%);
  font-size: 18px;
  cursor: pointer;
  transition: none;
  color: var(--accent);
  background-color: transparent;
  border: none;
  &:hover {
    color: var(--accentHover);
  }
}

.edit, .remove {
  cursor: pointer;
}

.edit {
  color: var(--accent);
  position: relative;
  left: 12px;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: var(--accentHover);
  }
}

@media(max-width: 1600px) {
  .indicatorWrapper {
    grid-template-columns: repeat(11, minmax(0, 1fr));
      & > div:first-child {
        grid-column: span 11 / span 11;
      }
      & > div:nth-child(2) {
        grid-column: span 5 / span 5;
      }
  }
  
}