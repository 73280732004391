.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 0;
    color: var(--main);
  }
  .closeIcon {
    position: absolute;
    right: -6px;
    top: -6px;
    color: var(--main);
  }
}

.modalContainer {
  background-color: var(--mainOpposite);
  padding: 24px;
  max-width: 1200px;
}

@media (max-width: 1600px) {
  .modalContainer {
    max-width: 1000px;
  }
}