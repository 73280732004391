.outerWrapper {
  .divider {
    height: 2px;
    background-color: var(--secondary);
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.wrapper {
  width: 100%;
  // border: 2px solid var(--borderColor);
  border-radius: 6px;
  
  &__inner {
    display: flex;
  }

  .leftSide, .rightSide {
    padding: 20px 20px 24px;
    padding-top: 0;
    flex: 1;
    & > div {
      display: flex;
      flex-direction: column;
      margin: 14px 0;
    }
    & > div > label {
      font-size: 17px;
      font-weight: 600;    
    }
  }
  .leftSide {
    padding-right: 24px;
    padding-left: 0;
    position: relative;
    flex: 5;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 92.5%;
      width: 2px;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--borderColor);
    }
  }
  .rightSide {
    flex: 6;
    padding-left: 24px;
    display: flex;
    padding-right: 0;
    flex-direction: column;
  }
  input, textarea {
    width: 100%;
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 200;
    }
  }
  textarea {
    min-height: auto;
  }
  select {
    padding: 8px 6px 8px 14px;
    border: 1px solid var(--borderColor);
    background-color: var(--secondary);
    color: var(--inputPlaceholder);
    max-width: 60px;
    border-radius: 6px;
    padding: 8px 6px 8px 20px;
  }
  input[type="radio"] {
    display: none;
  }
  .tabLabel { 
    font-size: 17px;
    cursor: pointer;
    position: relative;
    margin-top: 4px;
    font-weight: 400;
    transition: none;
    color: var(--accent);
    &:hover {
      color: var(--accentHover);
    }
    &.active {
      transition: color .75s linear;
      color: #000;
    }
    
  }

  .tabLabelStatic {
    text-transform: capitalize;
    color: var(--color);
    cursor: auto;
  }
}

.intervalScoresWrapper {
  border-bottom: 2px solid var(--borderColor);
  padding-bottom: 14px;
  margin-bottom: 14px;
  .row {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    position: relative;
    margin-top: 8px;
    &:first-child, &:nth-child(2) {
      margin-top: 0;
    }
    .col {
      flex: 1;
      max-width: 30.5%;
      input {
        font-size: 14px;
        width: 100%;
        padding-right: 2px;
      }
    }
    &.descriptiveRow {
      .col:first-child {
        max-width: 62.5%;
      }
    }
    &.descriptiveRowEdit {
      justify-content: flex-start;
      .col:nth-child(2) {
        margin-left: 8px;
        max-width: 25%;
      }
    }
  }
}


.addMoreButton {
  display: flex;
  margin-left: auto;
  align-self: flex-end;
  border: 1px solid var(--borderColor);
  padding: 10px 18px;
  background-color: transparent;
  color: var(--accent);
  font-size: 15px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: var(--accentHover);
  }
}

.buttonsWrapper {
  flex-direction: row !important;
  justify-content: flex-end;
  margin-top: auto !important;
  margin-bottom: 0 !important;
  button[type="submit"], .btn { margin-left: 0; }
  button:first-child {
    margin-right: 20px;
  }
}

.deleteBtn {
  position: absolute;
  right: -6px;
  top: -6px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-top: 10px;
  color: var(--errorRed);
  // transform: translateY(100%);
  &:hover {
    color: var(--errorRedHover);
  }
}

.colEditValueScore {
  max-width: 25% !important;
  margin-right: 20px !important;
}

@media(max-width: 1600px) {
  .deleteBtn {
    right: -6px;
  }
}

