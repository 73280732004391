.diagnosesResult {
  background-color: transparent;
  width: 100%;
  min-height: 0;
  padding: 3px;
  padding-bottom: 10px 0;
}
.diagnoseChip {
  color: var(--accent);
  padding: 2px 4px 6px;
  display: inline-block;
  background-color: transparent;
  // margin-bottom: 12px;
  // border: 1px solid var(--accent);
  font-size: 16px;
  position: relative;
  margin-right: 16px;
  // width: 75%;
  // &:nth-child(odd) {
  //   background-color: var(--secondary);
  // }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
    width: 2px;
    height: 24px;
    background-color: var(--borderColor);
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  &:hover {
    color: var(--accentHover);
  }
}
.chipContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.input {
  margin-left: 5px;
  max-width: 30px;
}
.remove {
  display: flex;
  margin-left: 5px;
  color: var(--errorRed);
  cursor: pointer;
  // margin-left: 20px;
  // margin-right: 40px;
  &:hover {
    color: var(--errorRedHover);
  }
}

.diagnoseLabel {
  position: relative;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -6px;
  //   left: 0;
  //   width: 200%;
  //   height: 2px;
  //   background-color: var(--borderColor);
  // }
}

.btn {
  background-color: transparent;
  color: var(--accent);
  margin-left: 12px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  &:hover {
    color: var(--accentHover);
  }
}

.customInput {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover) !important;
    }
  }
}