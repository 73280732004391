.diagnosesResult {
  width: 100%;
  min-height: 40px;
  margin-top: 12px;
}
.diagnoseChip {
  color: #000;
  padding: 2px 4px;
  padding-left: 0;
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
    width: 2px;
    height: 24px;
    background-color: var(--borderColor);
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.chipContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--accent);
  &:hover {
    color: var(--accentHover);
  }
}
.input {
  margin-left: 5px;
  max-width: 30px;
}

.autocompleteWrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 95px;
  width: 350px;
  transform: translateY(-10px);
}

.autocomplete {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover) !important;
    }
  }
}

.btn {
  margin-left: 12px;
  font-size: 18px;
}

.remove {
  margin-top: 5px;
  margin-left: 5px;
  color: var(--errorRed);
  cursor: pointer;
  &:hover {
    color: var(--errorRedHover);
  }
}

@media(max-width: 800px) {
  .autocompleteWrapper {
    flex-direction: column;
    max-width: 75%;
    .btn {
      margin-left: auto;
    }
  }
}