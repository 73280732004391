.diagnosesResult {
  // background-color: #fff;
  width: 100%;
  min-height: 100px;
}
.patientSection {
  position: relative;
}
.newPatientModal {
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  background-color: #0288d1;
  color: #fff;
  right: -26px;
  z-index: 100001;

  transform: translate(100%);
  min-width: 300px;
}

.newModalWrapper {
  width: 500px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
}

/** new patient modal */
.patientModalHeader {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(25, 118, 210);
  display: inline-block;
  width: 100%;
  color: #fff;
  padding: 10px;
}
.patientModalContent {
  padding: 10px;
  color: '#fff';
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--accent);
  cursor: pointer;
  font-size: 18px;
  margin-left: auto;
  &:hover {
    color: var(--accentHover);
  }
}

.patientsLayoutWrapper {
  display: flex;
  &__table {
    flex: 2;
    overflow-x: hidden;
    position: relative;
  }
  &__form {
    overflow: hidden;
    flex: 0;
    opacity: 0;
    max-height: 0;
    transition: flex 0.5s cubic-bezier(0.52, 0.01, 0, 1),
      opacity 0.25s ease-in-out 0s;
    &.opened {
      max-height: none;
      margin-left: 20px;
      flex: 1;
      opacity: 1;
      transition: flex 0.5s cubic-bezier(0.52, 0.01, 0, 1),
        opacity 0.25s ease-in-out 0.5s;
    }
  }
}

.absoluteBtn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}
.warningIcon {
  &.error {
    color: var(--errorRed);
  }
  &.warning {
    color: var(--warningYellow);
  }
}

@media (max-width: 1600px) {
  .patientsLayoutWrapper {
    &__table {
      flex: 6;
    }
    &__form {
      &.opened {
        flex: 4;
      }
    }
  }
}
