.box {
	display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
	height: 200px;
	padding: 1em;
	position: relative;
	margin-bottom: .25em;
	transition: .3s color, .3s border, .3s transform, .3s opacity;
}

.loader {
	border: 1px solid var(--accent);
	border-radius: 50%;
	animation: 1s loader linear infinite;
	position: relative;
  width: 55px;
  height: 55px;
  
	&:before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		top: -.3em;
		left: 50%;
		border: .3em solid var(--accent);
		border-radius: 50%;
	}
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}