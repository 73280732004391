.main {
  // padding-top: 10vh;
  background-color: var(--background);
  // padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}

.container {
  margin-top: 0 !important;
}

.logo {
  // position: fixed;
  // bottom: 0;
  // right: 60px;
  margin-top: auto;
  margin-left: auto;
  margin-right: 60px;
  padding-top: 20px;
  font-size: 48px;
  font-family: 'Dancing Script';
  color: var(--logoColor);
  // cursor: pointer;
}

@media(max-width: 800px) {
  .main {
    padding-top: 80px;
  }
}