.indicatorsWrapp {
  // background: #fff;
  color: #000;
  padding: 5px;
  width: 100%;
  overflow-y: auto;
  min-height: 40px;
  max-height: 170px;
  color: var(--main);
}

.indicatorItem {
  display: inline-block;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 7px;
  margin-left: 7px;
  margin-bottom: 7px;
}
.indicatorItemContent {
  display: flex;
  align-items: center;
}
.input {
  margin-left: 5px;
  max-width: 55px;
  text-align: right;
}

.selectList {
  width: 80px;
}
.remove {
  margin-left: 5px;
  color: var(--errorRed);
  cursor: pointer;
   &:hover {
    color: var(--errorRedHover);
  }
}

.btn {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--accent);
  cursor: pointer;
  font-size: 18px;
  margin-left: 12px;
  &:hover {
    color: var(--accentHover);
  }
}

.customInput {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover) !important;
    }
  }
}

.table {
  .tableHead {
    .tableRow {
      .tableCell {
        color: var(--main);
        padding: 4px 0 !important;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .tableBody {
    .tableRow {
      position: relative;
      .tableCell {
        color: var(--main);
        border: none !important;
        padding: 4px 0 !important;
        font-size: 14px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
      }
      // &:nth-child(odd) {
      //   .tableCell {
      //     background-color: var(--secondary);
      //   }
      // }
    }
  }
  input {
    text-align: left;
  }
  select {
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background-color: var(--secondary);
    color: var(--main);
  }
}