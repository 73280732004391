.wrapper {
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0;
      &:nth-child(2) {
        margin-left: 8px;
      }
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .divider {
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
    height: 2px;
    background-color: var(--secondary);
  }
}

.commentWrapper {
  position: relative;
  padding-top: 20px;
  &__date {
    font-size: 14px;
    color: var(--secondaryText);
    margin-bottom: 8px;
  }
  &__comment {
    font-size: 15px;
    padding-right: 15%;
  }
  .commentDivider {
    width: 100%;
    height: 2px;
    background-color: var(--secondary);
    margin-top: 16px;
  }
  .icon {
    position: absolute;
    right: 0;
    bottom: 4px;
    cursor: pointer;
    svg {
      width: 21px;
      height: 21px;
    }
  }
  .editIconWrapper {
    color: var(--accent);
    transform: translate(-100%);
    right: 6px;
    &:hover {
    color: var(--accentHover);
  }
  }
  .deleteIconWrapper {
    color: var(--errorRed);
    &:hover {
      color: var(--errorRedHover);
    }
  }
  &:first-child {
    padding-top: 0;
  }
  &.selected {
    color: var(--accent);
    &:hover {
      color: var(--accentHover);
    }
    .commentWrapper__date {
      color: var(--accent);
      &:hover {
        color: var(--accentHover);
      }
    }
  } 

}

.customChip {
  background-color: var(--secondary);
  padding: 2px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 3px;
  svg {
    max-width: 20px;
  }
  .fullName {
    display: flex;
  }
}