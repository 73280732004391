.wrapper {
  .divider {
    height: 2px;
    background-color: var(--secondary);
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 6px;
  }
  &__caretakerList {
    & > div {
      position: relative;
      font-size: 16px !important;
      background-color: var(--secondary);
      padding: 0 6px;
      border-radius: 6px;
      span {
        display: inline-block;
        margin-left: 4px;
      }
      label {
        font-size: 16px !important;
      }
      .divider {
        width: 100%;
        transform: translate(0, 0);
      }
      &.active {
        background-color: #bfffbf;
        color: #1F2023;
        .divider {
          background-color: #bfffbf;
        }
      }
      // &.active {
      //   &::before {
      //     content: '';
      //     position: absolute;
      //     left: 0;
      //     width: 15px;
      //     height: 100%;
      //     background-color: #BFFFBF;
      //     top: 0;
      //   }
      // }
    }
  }
}

.activeAnimationWrapper {
  margin-top: 4px;
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
  font-size: 17px;
  transition: color .2s linear;
}