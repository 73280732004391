.modalWrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    max-width: 800px;
    &__content {
      width: 100%;
      background-color: var(--mainOpposite);
      padding: 12px 24px;
      border-radius: 6px;
      h2 {
        margin-top: 0;
      }
    }
  }
}

@media(max-width: 800px) {
  .modalWrapper {
    &__inner {
      min-width: 360px;
    }
  }
}