.wrapper {
  input, textarea {
    width: 100%;
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 200;
    }
  }
  textarea {
    min-height: 120px;
  }
}


// .label {
//   color: var(--inputPlaceholder);
//   display: block;
//   font-size: 17px;
//   line-height: 16px;
//   margin-bottom: 4px;
// }
