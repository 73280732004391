.usersWrapper {
  display: flex;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
    & > div:first-child {
      flex: 9;
      overflow: hidden;
    }
    & > div:nth-child(2) {
      flex: 4;
    }
    .addNewBtn {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
      font-size: 18px;
    }
}

.edit, .remove {
  cursor: pointer;
}

.edit {
  color: var(--accent);
  position: relative;
  left: 12px;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: var(--accentHover);
  }
}

.remove {
  color: var(--errorRed);
  &:hover {
    color: var(--errorRedHover);
  }
}

.outerWrapper {
  .divider {
    height: 2px;
    background-color: var(--secondary);
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.formWrapper {
  width: 100%;
  // border: 2px solid var(--borderColor);
  // border-radius: 6px;
  // padding: 20px 20px 24px;
  input, textarea {
    width: 100%;
    max-width: 100%;
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 200;
    }
  }
  textarea {
    min-height: 100px;
  }
  select {
    border: 1px solid var(--borderColor);
    background-color: var(--secondary);
    color: var(--inputPlaceholder);
    max-width: 150px;
    border-radius: 6px;
    padding: 8px 6px 8px 8px;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
    margin-top: auto !important;
    margin-bottom: 0 !important;
    button[type="submit"], .btn { margin-left: 0; }
    button:last-child {
      margin-left: 20px;
    }
  }

 
}

.filterWrapperOuter {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.filterWrapper {
  display: flex;
  align-items: center;
  color: var(--accent);
  cursor: pointer;
  & > span {
    display: inline-block;
    margin-left: 8px;
    font-size: 18px;
  }
  svg {
    circle, line {
      stroke: var(--accent) !important;
    }
    &:hover {
      circle, line {
        stroke: var(--accentHover) !important;
      }
    }
  }
  &:hover {
    color: var(--accentHover);
  }
}