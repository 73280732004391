.indicatorsWrapp {
  background: transparent;
  color: #000;
  padding: 5px;
  padding-left: 0;
  width: 97%;
  overflow-y: auto;
  min-height: 40px;
  max-height: 180px;
}

label {
  font-size: 17px;
  font-weight: 600;
}

.indicatorTitle {
  padding-bottom: 7px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.indicatorItem {
  display: inline-block;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 7px;
  margin-left: 7px;
  margin-bottom: 7px;
}
.indicatorItemContent {
  display: flex;
  align-items: center;
}
.input {
  margin-left: 5px;
  max-width: 55px;
  text-align: right;
}

.selectList {
  width: 80px;
}
.edit {
  // display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px !important;
  color: var(--accent) !important;
  cursor: pointer;
  span {
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 4px;
  }
  &:hover {
    color: var(--accentHover) !important;
  }
}
.remove {
  margin-left: 5px;
  color: var(--errorRed);
  cursor: pointer;
}

.autocomplete {
  // padding: 0;
  & > div > div::before {
    border: none;
  }
}
.customInput {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover);
    }
  }
}

.table {
  .tableHead {
    .tableRow {
      .tableCell {
        color: var(--main);
        padding: 4px 0 !important;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .tableBody {
    .tableRow {
      position: relative;
      .tableCell {
        color: var(--main);
        border: none !important;
        padding: 4px 0 !important;
        font-size: 14px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
      }
    }
  }
  select {
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background-color: var(--secondary);
    color: var(--main);
  }
}

.remove { color: var(--errorRed) !important; }

.addMoreBtn {
  background-color: transparent;
  color: var(--accent);
  margin-left: 12px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  &:hover {
    color: var(--accentHover);
  }
}