.topBar {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 340px;
  z-index: 50;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 60px;
  padding-top: 15px;
  &__user {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.themeSwitcherWrapper {
  //margin-left: 400px;
  margin-right: 20px;
}

.topBar.treatmantLayout {
  .themeSwitcherWrapper {
    // margin-left: 500px;
    margin-right: 20px;
  }
}

@media(max-width: 800px) {
  .topBar {
    padding-left: 0;
    padding-right: 0;
  }
}