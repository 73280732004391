:root {
  /* Your default theme */
  --background: #fff;
  --sidebarBg: #f8f8f8;
  --accent: #1e90ff;
  --accentHover: rgba(37, 99, 235, 1);
  --contrastColor: #f1f5f9;
  --main: #000;
  --mainOpposite: #fff;
  --secondary: #f3f5f7;
  --mainText: #000;
  --lightHover: #fff;
  --tableOddRow: #fafafa;
  --tableCellTextColor: #334155;
  --borderColor: #e5e7eb;
  --logoColor: #bcbfc4;
  --inputPlaceholder: #1f1f1f;
  --errorRed: red;
  --errorRedHover: rgba(255, 100, 100, 1);
  --secondaryText: rgba(147, 147, 147, 1);
  --warningYellow: #ffc700;
  --tableHeaderBg: rgba(228, 228, 228, 1);
}

[data-theme='dark'] {
  --background: #1f2023;
  --sidebarBg: #2d2e30;
  --accent: #ffff66;
  --accentHover: rgba(253, 224, 71, 1);
  --contrastColor: #f1f5f9;
  --main: #fff;
  --mainOpposite: #1f2023;
  --secondary: #3a3a3a;
  --mainText: #f1f5f9;
  --lightHover: #000;
  --tableOddRow: #2c2c2c;
  --tableCellTextColor: #dfdfdf;
  --borderColor: #514f4f;
  --logoColor: #757373;
  --inputPlaceholder: #9e9e9e;
  --errorRed: rgba(255, 100, 100, 1);
  --errorRedHover: rgba(255, 125, 125, 1);
  --secondaryText: rgba(190, 190, 190, 1);
  --warningYellow: #ffc700;
  --tableHeaderBg: rgba(102, 102, 102, 1);
}

body {
  color: var(--mainText) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.btn {
  display: flex;
  margin-left: auto;
  margin-top: auto;
  background-color: transparent;
  color: var(--accent);
  font-size: 22px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  color: var(--accentHover);
}

.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.formControl {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.formControl:first-child {
  margin-top: 0;
}

.formControl:last-child {
  margin-bottom: 0;
}

.error {
  color: var(--errorRed);
}

.MuiInput-underline {
  border-color: var(--borderColor) !important;
  color: var(--main) !important;
}

.MuiInput-underline::before {
  border-color: var(--borderColor) !important;
}

.MuiPagination-ul {
  justify-content: center;
}

.MuiPagination-ul li button {
  color: var(--accent);
}

.MuiPagination-ul li button:hover {
  color: var(--accentHover);
}

.radioLabel {
  position: relative;
  cursor: pointer;
}

.radioLabel input[type='radio'] {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
}

.radioLabel .custom {
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: var(--secondaryText);
  border-radius: 50%;
  border: 1px solid var(--borderColor);
}

.radioLabel input[type='radio']:checked + .custom {
  background: var(--accent);
}

.MuiChip-root.MuiChip-filled {
  background-color: var(--secondary);
}

.MuiChip-label {
  color: var(--mainText);
}

.MuiChip-colorPrimary {
  background-color: var(--accent) !important;
}

.MuiChip-colorPrimary .MuiChip-label {
  color: var(--secondary) !important;
}

.MuiChip-colorPrimary svg {
  fill: var(--secondary) !important;
}

.MuiSelect-select {
  color: var(--accent) !important;
}

.MuiSelect-select svg path {
  fill: var(--accent) !important;
}

.style_editable__yX1Rs
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-1cg28ds-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1xp solid greenyellow;
}
