.divider {
  height: 2px;
  background-color: var(--secondary);
  width: calc(100% + 48px);
  transform: translate(-24px);
  margin: 16px 0;
}
h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

.wrapper {
  width: 100%;
  // border: 2px solid var(--borderColor);
  border-radius: 6px;
  // padding: 20px 20px 24px;
   input {
    width: 100%;
    margin-top: 2px;
    border: 1px solid var(--borderColor);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;
    font-family: inherit;
    background-color: var(--secondary);
    color: var(--main);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 200;
    }
  }
  &__inner {
    &__form {
      label {
        font-size: 17px;
        font-weight: 600;   
      }
      input {
        font-size: 14px;
        width: 100%;
        padding-right: 2px;
      }
      .formControl {
        margin: 20px 0;
        position: relative;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.customInput {
  & > div {
    background-color: transparent !important;
    & > div {
      padding: 0 !important;
      background-color: transparent;
      input {
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
  svg {
    fill: var(--accent) !important;
    &:hover {
      fill: var(--accentHover) !important;
    }
  }
}

.textarea {
  font-family: 'Inter';
  width: 100%;
  margin-top: 2px;
  border: 1px solid var(--borderColor);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-family: inherit;
  background-color: var(--secondary);
  color: var(--main);
  &::placeholder {
    color: var(--inputPlaceholder);
    font-weight: 200;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  & > button {
    margin-left: 10px;
  }
  & > button:first-child {
    margin-left: 0;
  }
  & > button:last-child {
    margin-right: 0;
  }
}

.btn {
  background-color: transparent;
  color: var(--accent);
  margin-left: 12px;
  font-size: 22px;
  border: none;
  cursor: pointer;
  &[disabled] {
    opacity: 0.5;
    // cursor: auto;
  }
  &:hover {
    color: var(--accentHover);
  }
}

.addPatientBtn {
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
}