.wrapper {
  .divider {
    height: 2px;
    background-color: var(--secondary);
    width: calc(100% + 48px);
    transform: translate(-24px);
    margin: 16px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    button:first-child {
      margin-right: 0;
      font-size: 18px;
      // font-weight: 300;
    }
    button:last-child {
      margin-left: 12px;
    }
  }
}