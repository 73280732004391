.divider {
  height: 2px;
  background-color: var(--secondary);
  width: calc(100% + 48px);
  transform: translate(-24px);
  margin: 16px 0;
}
h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

.wrapper {
    width: 100%;
    border-radius: 6px;
    
    &__inner {
      display: block;
      // padding: 20px 20px 24px;
    }
  
    input, textarea {
      width: 100%;
      margin-top: 2px;
      border: 1px solid var(--borderColor);
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 16px;
      font-family: inherit;
      background-color: var(--secondary);
      color: var(--main);
      &::placeholder {
        color: var(--inputPlaceholder);
        font-weight: 200;
      }
    }
    }
    .btnsWrapper{
      margin-top: 20px;
      display: flex;
      flex-direction:row !important ;
      justify-content: flex-end;
      .cancelBtn {
        margin-right: 20px !important;
      }
      button {
        margin-left: 0 !important;
      }
    }

    .textArea {
      min-height: 75px;
    }

    .MKV {
      max-width: 175px;
    }