.wrapper {
  position: fixed;
  inset: 0;
  background-color: transparent;
  z-index: 299;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.35);
  &__inner {
    position: absolute;
    right: 0;
    width: 90%;
    max-width: 550px;
    height: 100%;
    background-color: var(--mainOpposite);
    box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.35);
    padding: 20px 24px 20px;
    overflow-y: auto;
    // transform: translate(100%);
  }
  .closeBtnWrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    color: var(--main);
    & > div {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
