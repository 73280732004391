.diagnosesWrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(11, minmax(0, 1fr));
    & > div:first-child {
      grid-column: span 11 / span 11;
    }
    // place-items: center;
    // & > div:nth-child(2) {
    //   grid-column: span 4 / span 4;
    // }
  }

  .edit, .remove {
    cursor: pointer;
  }
  
  .edit {
    color: var(--accent);
    position: relative;
    left: 12px;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: var(--accentHover);
    }
  }

  @media(max-width: 1600px) {
    .diagnosesWrapper {
      grid-template-columns: repeat(10, minmax(0, 1fr));
      & > div:first-child {
        grid-column: span 10 / span 10;
      }
      // & > div:nth-child(2) {
      //   grid-column: span 4 / span 4;
      // }
    }
  
  }
  
  .createButton {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    font-size: 18px;
  }