.profileBar {
  display: flex;
  align-items: center;
  & > div:first-child {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    .triangle {
      width: 0; 
      height: 0; 
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid var(--accent);
      display: inline-block;
      margin-right: 6px;
    }
  }
}