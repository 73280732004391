.wrapper {
  width: 100%;
  color: var(--main);
  position: relative;
  min-height: 90px;
  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--borderColor);
  }
  h1 {
    text-align: center;
    font-size: 44px;
    line-height: 44px;
    width: fit-content;
    margin: 0;
    position: relative;
    color: var(--main);
  }
  .flexNew {
    display: flex;
    gap: 2px;
    // font-weight: 500;
    align-items: flex-end;
    font-size: 20px;
    // color: var(--secondaryText);
    margin-top: 20px;
    label {
      color: var(--main);
      font-size: 20px;
    }
  }
  .codeRow {
    display: flex;
    align-items: flex-end;
    & > span:last-child {
      cursor: pointer;
      // font-weight: 600;
      font-size: 22px;
      color: var(--accent);
      display: flex;
      gap: 4px;
      align-items: center;
      &:hover {
        color: var(--accentHover);
      }
    }
  }
  
  .editIcon {
    display: inline-block;
    color: var(--accent);
    // position: absolute;
    // right: -16px;
    // top: 50%;
    // transform: translate(100%, calc(-50% - 3px));
    z-index: 99;
    height: fit-content;
    cursor: pointer;
    margin-left: 8px;
    &:hover {
      color: var(--accentHover);
    }
  }
  .flex {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    transform: translate(50%);
  }
  &__row {
    position: relative;
    margin: 0;
    text-align: left;
    span:first-child {
      font-weight: 600;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      font-size: 18px;
      transform: translate(calc(-100% - 6px), calc(-50% - 2px));
      white-space: nowrap;
    }
    span:last-child {
      font-weight: 500;
      // font-size: 15px;
      color: var(--secondaryText);
    }
    &.codeRow {
      & > span:last-child {
        cursor: pointer;
        // font-weight: 600;
        font-size: 22px;
        color: var(--accent);
        display: flex;
        gap: 4px;
        align-items: center;
        &:hover {
          color: var(--accentHover);
        }
      }
    }
  }

  button {
    position: absolute;
    right: 0;
    bottom: -8px;
  }

  .closeTreatmentBtn {
    color: var(--errorRed);
    &:hover {
      color: var(--errorRedHover);
    }
  }
}

@media(max-width: 800px) {
  .wrapper {
    padding-bottom: 46px;
    h1 {
      text-align: left;
    }
    .flexNew {
      display: block;

    }
  }
}